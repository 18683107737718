<template>
  <div class="overflow-y-auto pb-4">
    <v-card
      outlined
    >
      <v-toolbar flat>
        <v-toolbar-title class="font-weight-bold">
          {{ item.title }}
        </v-toolbar-title>
        <v-spacer />
        <v-btn
          v-if="item.type === 'product'"
          :loading="updatingItem"
          @click="showDeleteDialog = true"
          icon
        >
          <v-icon>mdi-delete</v-icon>
        </v-btn>
        <v-btn
          color="green"
          v-if="supportsStockControl && canDisableStockControl && false"
          @click="disableStockControl"
          :disabled="true"
        >
          {{ $t('disableStock') }}
        </v-btn>
        <v-btn
          color="green"
          v-if="supportsStockControl && canEnableStockControl && false"
          @click="enableStockControl"
          :disabled="true"
        >
          {{ $t('enableStock') }}
        </v-btn>
        <v-btn
          icon
          @click="edit"
          :disabled="unSupportedQuickEditItemTypes.includes(item.type)"
        >
          <v-icon>mdi-lightning-bolt</v-icon>
        </v-btn>
        <v-btn
          icon
          @click="sellOnlineDialog"
        >
          <v-icon>mdi-link</v-icon>
        </v-btn>
        <v-btn
          @click="redirectOrOpenUpdateModal"
          icon
        >
          <v-icon>mdi-cog</v-icon>
        </v-btn>
      </v-toolbar>
      <v-card-text
        class="pt-0 pb-0"
        style="font-size: 20px;"
      >
        {{ formatCurrency(item.price) }}
      </v-card-text>
      <v-card-text class="py-0 pt-0 pb-0 caption">
        <v-chip
          x-small
          outlined
          class="mr-3"
        >
          {{ $t('uuid') }}
        </v-chip>
        <v-chip
          x-small
          outlined
          class="mr-3"
        >
          {{ item.uuid }}
        </v-chip>
        <v-chip
          x-small
          outlined
          class="mr-3"
        >
          {{ $t('lastEdited') }}: {{ lastEdited }}
        </v-chip>
      </v-card-text>
      <v-tabs v-model="selectedTab">
        <template v-for="tab in tabs">
          <v-tab
            :key="tab.key"
            :href="'#' + tab.key"
          >
            {{ tab.label }}
          </v-tab>
        </template>
      </v-tabs>
    </v-card>
    <v-tabs-items
      class="mt-3 transparent"
      v-model="selectedTab"
    >
      <template v-for="tab in tabs">
        <v-tab-item
          class="transparent"
          :key="tab.key"
          :value="tab.key"
        >
          <v-card
            outlined
            v-if="selectedTab === 'bookings'"
            class="transparent"
          >
            <BookingListSimple
              :bookings="bookings"
              @selectBooking="selectBooking"
              :call-to-actions="callToActions"
              show-booking-stats
            />
          </v-card>
          <v-card
            outlined
            v-if="selectedTab === 'subscriptions'"
            class="transparent"
          >
            <SubscriptionList :subscriptions="subscriptions" />
          </v-card>
          <v-card
            outlined
            v-if="selectedTab === 'giftcards'"
            class="transparent"
          />
          <v-card
            outlined
            v-if="selectedTab === 'coupons'"
            class="transparent"
          />
          <v-card
            outlined
            v-if="selectedTab === 'stock'"
            class="transparent"
          >
            <StockList :stock-entries="stockEntries" />
          </v-card>
          <v-card
            outlined
            v-if="selectedTab === 'stats'"
            class="transparent"
          >
            <v-row dense>
              <v-col cols="12">
                <v-card
                  outlined
                >
                  <v-card-title
                    flat
                  >
                    {{ $t('categories') }}
                  </v-card-title>
                  <v-card-text>
                    <v-btn
                      v-for="category in item.categories"
                      :value="item.categories[category]"
                      :key="item.categories[category]"
                      class="ma-1"
                      label
                      depressed
                      small
                      @click="showCategoryProfile(category)"
                    >
                      {{ category.name }}
                    </v-btn>
                  </v-card-text>
                </v-card>
              </v-col>
              <v-col cols="12">
                <FilterHeader
                  use-date
                  @newValues="updateFilters($event)"
                />
              </v-col>
              <v-col
                cols="12"
                md="4"
              >
                <TransientDash
                  :key="item.uuid + ':sales:' + startTimeInMillis + ':' + endTimeInMillis"
                  :title="$t('sales')"
                  namespace="item_aggregate_value"
                  :config="itemAggregateValueConfig"
                  :start-time-in-millis="startTimeInMillis"
                  :end-time-in-millis="endTimeInMillis"
                />
              </v-col>
              <v-col
                cols="12"
                md="4"
              >
                <TransientDash
                  :key="item.uuid + ':cost:' + startTimeInMillis + ':' + endTimeInMillis"
                  :title="$t('cost')"
                  namespace="item_cost_aggregate_value"
                  :config="itemAggregateValueConfig"
                  :start-time-in-millis="startTimeInMillis"
                  :end-time-in-millis="endTimeInMillis"
                />
              </v-col>
              <v-col
                cols="12"
                md="4"
              >
                <TransientDash
                  :key="item.uuid + ':profit:' + startTimeInMillis + ':' + endTimeInMillis"
                  :title="$t('profit')"
                  namespace="item_profit_aggregate_value"
                  :config="itemAggregateValueConfig"
                  :start-time-in-millis="startTimeInMillis"
                  :end-time-in-millis="endTimeInMillis"
                />
              </v-col>
              <v-col cols="12">
                <TransientDash
                  :key="item.uuid + ':dates:' + startTimeInMillis + ':' + endTimeInMillis"
                  :title="$t('itemsSoldByDates')"
                  namespace="item_aggregate_value_by_date"
                  :config="itemAggregateValueConfig"
                  :start-time-in-millis="startTimeInMillis"
                  :end-time-in-millis="endTimeInMillis"
                />
              </v-col>
              <v-col
                cols="12"
              >
                <TransientDash
                  :key="item.uuid + ':popular:' + startTimeInMillis + ':' + endTimeInMillis"
                  :title="$t('popularWithThisItem')"
                  namespace="item_popular_with_list"
                  :config="itemAggregateValueConfig"
                  :start-time-in-millis="startTimeInMillis"
                  :end-time-in-millis="nowInMillis"
                />
              </v-col>
            </v-row>
          </v-card>
          <v-card
            outlined
            v-if="selectedTab === 'variations'"
            class="transparent"
          >
            <v-row>
              <v-col cols="12">
                <v-card
                  outlined
                >
                  <v-card-title
                    flat
                  >
                    {{ $t('defaultVariations') }}
                  </v-card-title>
                  <v-card-text
                    v-if="typeof item.default_variations === 'undefined' || item.default_variations === null || item.default_variations.length <= 0"
                  >
                    {{ $t('noDefaultVariationsOnItem') }}
                  </v-card-text>
                  <v-card-text
                    v-else
                  >
                    <v-btn
                      v-for="variation in item.default_variations"
                      :key="variation.uuid"
                      class="ma-1"
                      label
                      depressed
                      small
                    >
                      {{ variation.label }}
                    </v-btn>
                  </v-card-text>
                </v-card>
              </v-col>
              <v-col cols="12">
                <v-card
                  outlined
                >
                  <v-card-title
                    flat
                  >
                    {{ $t('variationSets') }}
                  </v-card-title>
                  <v-card-text
                    v-if="typeof item.variation_sets === 'undefined' || item.variation_sets === null || item.variation_sets.length <= 0"
                  >
                    {{ $t('noVariationSetsOnItems') }}
                  </v-card-text>
                  <v-card-text
                    v-else
                  >
                    <v-btn
                      v-for="variation_set in item.variation_sets"
                      :key="variation_set.uuid"
                      class="ma-1"
                      label
                      depressed
                      small
                    >
                      {{ variation_set.label }}
                    </v-btn>
                  </v-card-text>
                </v-card>
              </v-col>
            </v-row>
          </v-card>
        </v-tab-item>
      </template>
    </v-tabs-items>
    <v-dialog
      v-if="showQuickEditDialog && item"
      v-model="showQuickEditDialog"
      @click:outside="closeQuickEditDialog"
      max-width="800"
    >
      <v-card>
        <v-card-title class="text-break">
          {{ $t('quickEditFor') }} {{ item.title }}
        </v-card-title>
        <v-card-text>
          <v-form v-model="valid">
            <v-container>
              <v-row>
                <v-col cols="12">
                  <v-text-field
                    :label="$t('title') + ' *'"
                    filled
                    hide-details="auto"
                    v-model="titleInput"
                    :rules="[rules.required, rules.whiteSpaceString, rules.trailingWhiteSpace]"
                  />
                </v-col>
                <v-col cols="12">
                  <v-text-field
                    :label="$t('sku') + ' *'"
                    filled
                    v-model="editedItem.sku"
                    :rules="[rules.required, rules.whiteSpaceString, rules.trailingWhiteSpace]"
                  />
                </v-col>
                <v-col cols="12">
                  <v-autocomplete
                    v-model="editedItem.categories"
                    filled
                    :label="$t('categories')"
                    :items="categories"
                    item-value="uuid"
                    item-text="name"
                  />
                </v-col>
                <v-col cols="12">
                  <PriceField
                    :label="$t('price')"
                    :value="editedItem.price"
                  />
                </v-col>
                <v-col cols="12">
                  <PriceField
                    :label="$t('salePrice')"
                    :value="editedItem.sale_price"
                  />
                </v-col>
                <v-col cols="12">
                  <WeekdayPrices
                    :weekday-prices="editedItem.weekdayPrices"
                    @addNewWeekdayPrice="addNewWeekdayPrice"
                    @removeWeekdayPrice="removeWeekdayPrice"
                    @updateWeekdayPrice="updateWeekdayPrice"
                  />
                </v-col>
                <v-col cols="12">
                  <SeasonalPrices
                    :seasonal-prices="editedItem.seasonalPrices"
                    @addNewSeasonalPrice="addNewSeasonalPrice"
                    @removeSeasonalPrice="removeSeasonalPrice"
                    @updateSeasonalPrice="updateSeasonalPrice"
                  />
                </v-col>
              </v-row>
            </v-container>
          </v-form>
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn
            color="error"
            text
            @click="closeQuickEditDialog"
          >
            {{ $t('cancel') }}
          </v-btn>
          <v-btn
            color="primary"
            :disabled="!valid"
          >
            {{ $t('save') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog
      v-if="showDeleteDialog"
      v-model="showDeleteDialog"
      max-width="500"
    >
      <v-card>
        <v-card-title>
          {{ $t('confirm') }}
        </v-card-title>
        <v-card-text>
          {{ $t('confirmDeleteText', { item: this.item.title }) }}
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn
            color="error"
            text
            @click="showDeleteDialog = false"
          >
            {{ $t('close') }}
          </v-btn>
          <v-btn
            color="primary"
            depressed
            :loading="updatingItem"
            @click="confirmDelete"
          >
            {{ $t('confirm') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import FilterHeader from '@/components/universalFilter/FilterHeader.vue'
import TransientDash from '../dash/TransientDash.vue'
import StockList from '@/components/stock/StockList'
import BookingListSimple from '@/components/booking/BookingListSimple'
import SubscriptionList from '@/components/SubscriptionList'
import moment from 'moment'
import PriceField from "@/components/PriceField"
import WeekdayPrices from "@/components/common/pricing/WeekdayPrices"
import SeasonalPrices from "@/components/common/pricing/SeasonalPrices"

export default {
    name: 'ItemProfile',
    props: {
      item: {
        type: Object,
        default: null
      }, 
      uuid: {
        type: String, 
        default: null
      }
    },
    components: {
      SeasonalPrices,
      WeekdayPrices,
      PriceField,
      TransientDash,
      StockList,
      BookingListSimple,
      SubscriptionList,
      FilterHeader
    },
    data() {
      return {
        oneDayInMillis: 86400000,
        selectedTab: 'stats',
        stockEntries: null,
        bookings: null,
        supportsNewItemCreation: ['product', 'coupon', 'giftcard'],
				bookingTypes: ['accommodation', 'trip', 'event', 'flight', 'upsell'],
        unSupportedQuickEditItemTypes: ['subscription', 'trip', 'accommodation', 'parking', 'flight'],
	      organizationAllowsBookingTabOnProductItems: [
			  '7dbcf598-dc8d-490b-8733-931deb49e967', // Tripical Ísland
	      ],
        subscriptions: null,
        showQuickEditDialog: false,
        editedItem: null,
        savingItem: false,
        valid: false,
        showDeleteDialog: false,
        updatingItem: false,
        seasonalPriceObject: {
          startInMillis: null,
          endInMillis: null,
          price: {
            amount: 0,
            currency_code: null
          },
          active: true
        },
        weekdayPriceObject: {
          weekday: null,
          hourStart: null,
          hourEnd: null,
          price: {
            amount: 0,
            currency_code: null
          },
          active: true
        }
      }
    },
    methods: {
      confirmDelete() {
        this.updatingItem = true
        const itemToUpdate = this.item
        itemToUpdate.isDeleted = true
        this.saveItem(itemToUpdate).finally(() => {
          this.showDeleteDialog = false
          this.updatingItem = false
        })
      },
      updateSeasonalPrice({ value, index }) {
        if(Array.isArray(this.editedItem.seasonalPrices)) {
          if(value && index >= 0) {
            this.editedItem.seasonalPrices[index] = value
          }
        }
      },
      removeSeasonalPrice(index) {
        if(Array.isArray(this.editedItem.seasonalPrices) && index >= 0) {
          this.editedItem.seasonalPrices.splice(index, 1)
        }
      },
      addNewSeasonalPrice() {
        if(!this.editedItem.seasonalPrices) {
          this.editedItem.seasonalPrices = []
        }
        if(typeof this.organization !== 'undefined' && this.organization !== null) {
          if(typeof this.organization.defaultCurrency !== 'undefined' && this.organization.defaultCurrency !== null) {
            if(typeof this.organization.defaultCurrency.code !== 'undefined' && this.organization.defaultCurrency.code !== null) {
              this.seasonalPriceObject.price.currency_code = this.organization.defaultCurrency.code
            }
          }
        }
        this.editedItem.seasonalPrices.push(JSON.parse(JSON.stringify(this.seasonalPriceObject)))
      },
      updateWeekdayPrice({ value, index }) {
        if(Array.isArray(this.editedItem.weekdayPrices) && this.editedItem.weekdayPrices.length > 0) {
          if(value && index >= 0) {
            this.editedItem.weekdayPrices[index] = value
          }
        }
      },
      removeWeekdayPrice(index) {
        if(Array.isArray(this.editedItem.weekdayPrices) && this.editedItem.weekdayPrices.length > 0) {
          if(index >= 0) {
            this.editedItem.weekdayPrices.splice(index, 1)
          }
        }
      },
      addNewWeekdayPrice() {
        if(!this.editedItem.weekdayPrices) {
          this.editedItem.weekdayPrices = []
        }
        if(typeof this.organization !== 'undefined' && this.organization !== null) {
          if(typeof this.organization.defaultCurrency !== 'undefined' && this.organization.defaultCurrency !== null) {
            if(typeof this.organization.defaultCurrency.code !== 'undefined' && this.organization.defaultCurrency.code !== null) {
              this.weekdayPriceObject.price.currency_code = this.organization.defaultCurrency.code
            }
          }
        }
        this.editedItem.weekdayPrices.push(JSON.parse(JSON.stringify(this.weekdayPriceObject)))
      },
      openQuickEditDialog() {
        this.editedItem = JSON.parse(JSON.stringify(this.item))
        this.showQuickEditDialog = true
      },
      closeQuickEditDialog() {
        this.showQuickEditDialog = false
        this.editedItem = null
      },
      saveEditedItem() {
        this.savingItem = true
        this.$store.dispatch('updateItem', this.editedItem).then(result => {
          if(result) {
            // Success
          } else {
            // Failure
          }
        }).catch(() => {
          // Failure
        }).finally(() => {
          this.savingItem = false
        })
      },
      showCategoryProfile(category) {
        this.$store.commit('updateContextOverlay', {
          'component':  'components/category/CategoryProfile',
          'props': {
            'uuid': category.uuid
          }
        })
      },
      redirectOrOpenUpdateModal() {
        if (this.supportsNewItemCreation.includes(this.item?.type)) {
          if(this.item.type === 'event' || this.item.type === 'product') {
            if(!this.item.openingHours || !Array.isArray(this.item.openingHours)) {
              this.item.openingHours = []
            }
          }
          this.item.imageUrl = null
          this.$store.commit('updateDataToMutate', {
            objectToMutate: this.item,
            formComponent: 'components/ItemForm',
            saveCallback: this.saveItem,
            title: `${this.$t('edit')} ${this.item.title}`

          })
        }
        else {
          window.open(`https://admin.salescloud.is/store/items/${this.item.uuid}/edit`, '_blank')
        }
      },
      updateFilters(filters) {
        this.startTimeInMillis = filters.startTimeInMillis
        this.endTimeInMillis = filters.endTimeInMillis
      },
      sellOnlineDialog() {
        this.$emit('sellOnline', this.item)
      },
      edit() {
        if(this.item.type === 'event' || this.item.type === 'product') {
          if(!this.item.openingHours || !Array.isArray(this.item.openingHours)) {
            this.item.openingHours = []
          }
        }
        this.item.imageUrl = null
        this.$store.commit('updateDataToMutate', {
          objectToMutate: this.item,
          formComponent: 'components/ItemForm',
          saveCallback: this.saveItem,
          title: `${this.$t('edit')} ${this.item.title}`

			  })
      },
      saveItem(itemToSave) {
        return this.$store.dispatch('updateItem', itemToSave).then(updatedItem => {
          if(updatedItem !== null) {
            this.$store.commit('updateActionSuccess', {
              message: this.$t('itemUpdated')
            })
          }
          else {
            this.$store.commit('updateActionError', {
              message: this.$t('error'),
              subMessage: this.$t('unknownErrorPleaseTryAgainIfErrorPersistsPleaseContactSupport')
            })
            return false
          }
          return updatedItem
        })
      },
      enableStockControl() {
        this.$emit('enableStockControl', this.item)
      },
      disableStockControl() {

      },
      getBookings() {
        this.$store.dispatch('getBookingsByItem', this.item.uuid).then(bookings => {
          this.bookings = bookings
        })
      },
      getSubscriptions() {
        const conditions = [
          {
            property: 'item.uuid',
            operator: 'equals',
            value: this.item.uuid
          }
        ]

        this.$store.dispatch('getSubscriptions', {conditions: conditions}).then(subscriptions => {
          this.subscriptions = subscriptions
        }).catch(error => {
          console.error('[ItemProfile] getSubscriptions error:', error)
        })
      },
      getCoupons() {

      },
      getGiftcards() {

      },
      getItem() {
        this.item = this.$store.state.items.find(item => item.uuid === this.uuid)
      },
      formatCurrency(price) {
        return this.$store.getters.formatAmountByCurrency(price)
      },
      getStockEntries() {
        this.$store.dispatch('getStockEntries', {
            entityType: 'item',
            entityId: this.item.uuid
          }).then(stockEntries => {
            this.stockEntries = stockEntries
          })
      },
      selectBooking(booking) {
        this.$store.commit('updateContextOverlay', {
          'component': 'components/booking/AddBookingCard',
          'props': {
            'bookingObject': booking,
            'bookingItem': this.item.uuid,
            'bookingTime': booking.startsAtTime,
            'bookingDuration': booking.endsAtTime - booking.startsAtTime
          }
        })
      },
      addBooking() {
        this.$store.commit('updateContextOverlay', {
          'component': 'components/booking/AddBookingCard',
          'props': {
            'bookingItem': this.item.uuid,
          }
        })
      }
    },
		mounted() {
			if ((typeof this.item === 'undefined' || this.item === null) && this.uuid !== null) {
				this.getItem()
			}
			// We always get bookings to handle things like selling product as an event the way Tripical is doing it
			this.getBookings()

      if(this.item?.type === 'subscription') {
			  this.getSubscriptions()
		  }
		},
    watch: {

      item(item) {
		  // Let us not fetch anything if item is set to null or undefined
	    if(item) {
		    if(this.stockControlIsEnabled) {
			    this.getStockEntries()
		    }

		    this.getBookings()

		    if(item.type === 'subscription') {
			    this.getSubscriptions()
		    }

		    /* if(item.type === 'coupon') {
		    }

		    if(item.type === 'giftcard') {
		    } */
	    }
      }
    },
    computed: {
      callToActions() {
        return [{
            title: this.$t('addBooking'),
            callback: this.addBooking,
            disabled: this.callToActionsCallbackStatus.disabled,
            message: this.callToActionsCallbackStatus.message
          }]
      },
      activeLocations() {
			  return this.$store.state.locations.filter(location => location.label !== null && location.label !== undefined && location.label !== '' && location.active === true)
		  },
      organization() {
        return this.$store.state.organization
      },
      titleInput: {
        get() {
          return this.editedItem.title
        },
        set(value) {
          this.editedItem.title = value
        }
      },
      rules() {
        return this.$store.getters.rules
      },
      categories() {
        return this.$store.state.categories
      },
      startTimeInMillis: {
        get() {
          return this.$store.state.profileStatsStartTimeInMillis
        },
        set(value) {
          this.$store.commit('updateProfileStatsStartTimeInMillis', value)
        }
      },
      endTimeInMillis: {
        get() {
          return this.$store.state.profileStatsEndTimeInMillis
        },
        set(value) {
          this.$store.commit('updateProfileStatsEndTimeInMillis', value)
        }
      },
      stockControlIsEnabled() {
        return this.supportsStockControl && this.item.availabilityMode === 'stock'
      },
      canDisableStockControl() {
        return this.supportsStockControl && this.item.availabilityMode === 'stock'
      },
      canEnableStockControl() {
        return this.supportsStockControl && this.item.availabilityMode !== 'stock'
      },
      supportsStockControl() {

        if(this.item.type === 'product') {
          return true
        }

        return false
      },
      callToActionsCallbackStatus(){
        if(this.item.type === 'event') {
          if(this.item.location !== null && typeof this.item.location !== 'undefined' && this.activeLocations.includes(location => location.uuid === this.item.location.uuid) || this.item.location === null) {
            return {disabled: false, message: ''}
          }
          return {disabled: true, message: '(Item location is disabled)'}
        }
        return {disabled: false, message: ''}
      },
      tabs() {

        const tabs = []

        tabs.push({
          key: 'stats',
          label: this.$t('keyStats')
        })

				tabs.push({
          key: 'variations',
          label: this.$t('variations')
        })

        if(this.stockControlIsEnabled) {
          tabs.push({
            key: 'stock',
            label: this.$t('stock')
          })
        }

        if(this.bookingTypes.includes(this.item.type) || (Array.isArray(this.bookings) && this.bookings.length > 0)) {
          tabs.push({
            key: 'bookings',
            label: this.$t('bookings')
          })
        }

        if(this.item.type === 'subscription') {
          tabs.push({
            key: 'subscriptions',
            label: this.$t('subscriptions')
          })
        }

        if(this.item.type === 'giftcard') {
          tabs.push({
            key: 'giftcards',
            label: this.$t('giftcards')
          })
        }

        if(this.item.type === 'coupon') {
          tabs.push({
            key: 'coupons',
            label: this.$t('coupons')
          })
        }

        return tabs
      },
      itemAggregateValueConfig() {
        return {
          item: this.item.uuid
        }
      },
      nowInMillis() {
        return new Date().getTime()
      },
      last24HoursStartTimeInMillis() {
        return new Date().getTime() - this.oneDayInMillis
      },
      last7DaysStartTimeInMillis() {
        return new Date().getTime() - (this.oneDayInMillis * 7)
      },
      last30DaysStartTimeInMillis() {
        return new Date().getTime() - (this.oneDayInMillis * 30)
      },
      editUrl() {
        return 'https://admin.salescloud.is/store/items/' + this.item.uuid + '/edit'
      },
			lastEdited() {
				if (this.item && this.item.changed !== undefined && this.item.changed !== null) {
					const d = new Date(this.item.changed * 1000)
					return moment(d).format('YYYY-MM-DD HH:mm:SS')
				}
				return ""
			}
    }
}
</script>
