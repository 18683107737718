<template>
  <v-row>
    <v-col
      class="pt-0 pb-0"
      :cols="showTaxSelection ? 4 : 6"
    >
      <v-text-field
        ref="amount"
        filled
        dense
        :label="label"
        v-model.number="amount"
        @input="updatePrice"
      />
    </v-col>
    <v-col
      class="pt-0 pb-0"
      :cols="showTaxSelection ? 4 : 6"
    >
      <v-autocomplete
        ref="currency_code"
        :outlined="outlined"
        filled
        dense
        :label="$t('currency')"
        :items="currencies"
        item-text="name"
        item-value="code"
        v-model="currency_code"
        @input="updatePrice"
      />
    </v-col>

    <v-col
      class="pt-0 pb-0"
      v-if="showTaxSelection"
      :cols="showTaxSelection ? 4 : 6"
    >
      <v-autocomplete
        ref="tax_rate"
        :outlined="outlined"
        filled
        dense
        :label="$t('tax')"
        :items="taxRates"
        item-text="title"
        item-value="uuid"
        v-model="taxRate"
        @input="updatePrice"
      />
    </v-col>
  </v-row>
</template>

<script>
export default {
  name: 'PriceField',
  props: {
    value: {
      type: Object,
      default: () => ({})
    },
    label: {
      type: String,
      default: ''
    },
    showTaxSelection: {
      type: Boolean,
      default: false
    },
    filled: {
      type: Boolean,
      default: true
    },
    outlined: {
      type: Boolean,
      default: false
    },
    selectedTaxRate: {
      type: String,
      default: null
    }
  },
  data() {
    return {
      amount: 0,
      currency_code: 'ISK',
      taxRate: null
    }
  },
  mounted() {
    if (this.value) {      
      this.amount = parseFloat(this.value.amount) || 0
      this.currency_code = this.value.currency_code || 'ISK'
      this.taxRate = this.getTaxRate()
      this.updateParent()
    }
  },
  methods: {
    updatePrice() {
      this.updateParent()
    },
    updateParent() {
      const amount = isNaN(parseFloat(this.amount)) ? 0 : parseFloat(this.amount)
      this.$emit('input', {
        amount,
        currency_code: this.currency_code,
        taxRate: this.taxRate
      })
    },
    getTaxRate() {
      if (this.showTaxSelection) {
        // If the product has a tax rate, return it
        if(this.value.data?.include_tax){
          return this.value.data.include_tax
        }
        
        // If the product doesn't have a tax rate, return the first tax rate
        if(!this.value.data && this.taxRates[0]?.uuid) {
          return this.taxRates[0].uuid
        }
      }

      // This option is used to set a default tax rate.
      // E.g. Sale or cost price should inherit the tax rate from the price of a product without it being displayed.
      if (this.selectedTaxRate) {
        return this.selectedTaxRate
      }

      return null
    }
  },
  computed: {
    currencies() {
      return this.$store.state.currencies || []
    },
    taxRates() {
      return this.$store.state.organizationTaxRates || []
    }
  }
}
</script>